import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import token from "jwt-decode";

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		api:	process.env.VUE_APP_API_APP,
		title: 'AD Race - Admin Panel',
		panel:	false,
		role:	null,
		user:	{},
		perfil:	{}
	},
	mutations: {
		setUser(state, user){
			state.user = user || {};
		},
		setRole(state, role){
			state.role = role || null;
		},
		setPerfil(state, perfil){
			state.perfil = perfil || {};
		},
	},
	actions: {

		//#region - //* Auth - Login
		async logIn({dispatch}, user){
			try{
				const resp = await dispatch('callApi',{endpoint:'auth/login', data:user});
				return Promise.resolve(resp);
			}catch(error){
				return Promise.reject(error);
			}
		},
		//#endregion

		//#region - //* Auth - SignUp
		async signUp({dispatch}, user){
			try{
				const resp = await dispatch('callApi',{endpoint:'auth/signup', data:user});
				return Promise.resolve(resp);
			}catch(error){
				return Promise.reject(error);
			}
		},
		//#endregion

		//#region - //* Auth - LogOut
		async logOut({dispatch}){
			try{

				const resp = await dispatch('callApi',{endpoint:'auth/logout'});
				await dispatch('deleteCookie');
				return Promise.resolve(resp);

			}catch(error){
				return Promise.reject(error);
			}
		},
		//#endregion

		//#region - //* Profile - Read Profile
		async readProfile({dispatch, commit}){
			try{
				const resp = await dispatch('callApi',{endpoint:'profile/read-profile'});
				commit('setPerfil', resp?.profile);
				return Promise.resolve(resp);
			}catch(error){
				return Promise.reject(error);
			}
		},
		//#endregion

		//#region - //* Validate Token
		async validToken({commit, dispatch}){
			try{
				const session = Vue.$cookies?.get('session');
				if(session){
					interface token {
						name?:	string;
						mail?:	string;
						role?:	string
					}

					const { name, mail, role }:token = token(session);
					
					commit('setUser', { name, mail });
					commit('setRole',  role );

				}else{
					throw { message:'invalid-token' };
				}
			}catch(e){
				await dispatch('deleteCookie');
			}
		},
		//#endregion

		//#region - //* AXIOS API
		async callApi({state,dispatch}, req){
			try{
				const { data, status } = await axios({
					url:	`${req?.api || state?.api}/${req?.endpoint}`,
					method:	req?.method || 'POST',
					data:	req?.data,
					withCredentials: true
				},);
				
				if(data?.refresh){
					await dispatch('validToken');
				}
				return Promise.resolve({...data, status:status });
			}catch(e){
				const data = await dispatch('errorResponse',e);
				return Promise.reject(data);
			}
		},
		//#endregion

		//#region - //* Error Response
		async errorResponse(_, error){
			const { response } = await error ;
			const alterna = { status:500, message:'Error de conexion' };
			const { status, data } = response || alterna;
			return  /^4.+/.test(status) ? { ...data, status:status } : alterna;
		},
		//#endregion

		//#region - //* Cookies - Delete Alls
		async deleteCookie({commit}){
			try{

				commit('setUser',	null);
				commit('setRole',	null);
				commit('setPerfil', null);

				return Promise.resolve(true);
			}catch(error){
				return Promise.reject(error);
			}
		},
		//#endregion
	},
});