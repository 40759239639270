import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import store from "@/store";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/auth/:id?",
    name: "auth",
    component: () => import("@/views/auth/router.vue"),
    redirect: {
      name: "login",
    },
    meta: {
      auth: true,
    },
    children: [
      {
        name: "login",
        path: "/login",
        component: () => import("@/views/auth/pages/login.vue"),
      },
      {
        name: "signup",
        path: "/signup",
        redirect: {
          name: "login",
        },
        //component: () => import('@/views/auth/pages/signup.vue'),
      },
      {
        name: "forgot",
        path: "/forgot",
        component: () => import("@/views/auth/pages/forgot.vue"),
      },
      {
        name: "reset",
        path: "/reset",
        component: () => import("@/views/auth/pages/reset.vue"),
      },
    ],
  },
  {
    path: "/:id?",
    name: "app",
    component: () => import("@/views/app/router.vue"),
    meta: {
      app: true,
      home: [
        { key: "super", path: "promoters" },
        { key: "admin", path: "promoters" },
        { key: "store", path: "store" },
        { key: "user", path: "promoters" },
      ],
    },
    children: [
      {
        name: "profile",
        path: "/profile",
        component: () => import("@/views/app/profile/profile.vue"),
        meta: {
          permissions: ["super", "admin", "store", "user"],
        },
      },
      {
        name: "promoters",
        path: "/promoters",
        component: () => import("@/views/promoters/router.vue"),
        redirect: {
          name: "promoters-promos",
        },
        meta: {
          permissions: ["super", "admin", "user"],
        },
        children: [
          {
            name: "promoters-promos",
            path: "/promoters/promos",
            component: () => import("@/views/promoters/promos/promos.vue"),
            redirect: {
              name: "promoters-promos-dashboard",
            },
            children: [
              {
                name: "promoters-promos-dashboard",
                path: "/promoters/promos/dashboard",
                component: () =>
                  import("@/views/promoters/promos/pages/dashboard.vue"),
              },
              {
                name: "promoters-promos-create",
                path: "/promoters/promos/create",
                component: () =>
                  import("@/views/promoters/promos/pages/create.vue"),
              },
              {
                name: "promoters-promos-extends",
                path: "/promoters/promos/extends",
                component: () =>
                  import("@/views/promoters/promos/pages/extends.vue"),
              },
            ],
          },
          {
            name: "promoters-marketplace",
            path: "/promoters/marketplace",
            component: () =>
              import("@/views/promoters/marketplace/marketplace.vue"),
            redirect: {
              name: "promoters-marketplace-store",
            },
            children: [
              {
                name: "promoters-marketplace-store",
                path: "/promoters/marketplace/store",
                component: () =>
                  import("@/views/promoters/marketplace/pages/store.vue"),
              },
              {
                name: "promoters-marketplace-items",
                path: "/promoters/marketplace/items",
                component: () =>
                  import("@/views/promoters/marketplace/pages/items.vue"),
              },
            ],
          },
        ],
      },
      {
        name: "store",
        path: "/store",
        component: () => import("@/views/store/router.vue"),
        redirect: {
          name: "store-products",
        },
        meta: {
          permissions: ["super", "store"],
        },
        children: [
          {
            name: "store-products",
            path: "/store/products",
            component: () => import("@/views/store/product/product.vue"),
            redirect: {
              name: "store-products-dashboard",
            },
            children: [
              {
                name: "store-products-dashboard",
                path: "/store/products/dashboard",
                component: () =>
                  import("@/views/store/product/pages/dashboard.vue"),
              },
              {
                name: "store-products-create",
                path: "/store/products/create",
                component: () =>
                  import("@/views/store/product/pages/create.vue"),
              },
            ],
          },
        ],
      },
      {
        name: "admin",
        path: "/admin",
        component: () => import("@/views/admin/router.vue"),
        redirect: {
          name: "admin-puzzles",
        },
        meta: {
          permissions: ["super", "admin"],
        },
        children: [
          {
            name: "admin-puzzles",
            path: "/admin/puzzles",
            component: () => import("@/views/admin/puzzles/puzzles.vue"),
            redirect: {
              name: "admin-puzzles-dashboard",
            },
            children: [
              {
                name: "admin-puzzles-dashboard",
                path: "/admin/puzzles/dashboard",
                component: () =>
                  import("@/views/admin/puzzles/pages/dashboard.vue"),
              },
            ],
          },
          {
            name: "admin-banners",
            path: "/admin/banners",
            component: () => import("@/views/admin/banners/banners.vue"),
            redirect: {
              name: "admin-banners-dashboard",
            },
            children: [
              {
                name: "admin-banners-dashboard",
                path: "/admin/banners/dashboard",
                component: () =>
                  import("@/views/admin/banners/pages/dashboard.vue"),
              },
            ],
          },
          {
            name: "admin-users",
            path: "/admin/users",
            component: () => import("@/views/admin/users/users.vue"),
            redirect: {
              name: "admin-users-dashboard",
            },
            children: [
              {
                name: "admin-users-dashboard",
                path: "/admin/users/dashboard",
                component: () =>
                  import("@/views/admin/users/pages/dashboard.vue"),
              },
            ],
          },
          {
            name: "admin-gamers",
            path: "/admin/gamers",
            component: () => import("@/views/admin/gamers/gamers.vue"),
            redirect: {
              name: "admin-gamers-dashboard",
            },
            children: [
              {
                name: "admin-gamers-dashboard",
                path: "/admin/gamers/dashboard",
                component: () =>
                  import("@/views/admin/gamers/pages/dashboard.vue"),
              },
            ],
          },
          {
            name: "admin-products",
            path: "/admin/products",
            component: () => import("@/views/admin/product/product.vue"),
            redirect: {
              name: "admin-products-dashboard",
            },
            children: [
              {
                name: "admin-products-dashboard",
                path: "/admin/products/dashboard",
                component: () =>
                  import("@/views/admin/product/pages/dashboard.vue"),
              },
            ],
          },
          {
            name: "admin-payment",
            path: "/admin/payment",
            component: () => import("@/views/admin/payment/payment.vue"),
            redirect: {
              name: "admin-payment-dashboard",
            },
            children: [
              {
                name: "admin-payment-dashboard",
                path: "/admin/payment/dashboard",
                component: () =>
                  import("@/views/admin/payment/pages/dashboard.vue"),
              },
            ],
          },
          {
            name: "admin-invoice",
            path: "/admin/invoice",
            component: () => import("@/views/admin/invoice/invoice.vue"),
            redirect: {
              name: "admin-invoice-dashboard",
            },
            children: [
              {
                name: "admin-invoice-dashboard",
                path: "/admin/invoice/dashboard",
                component: () =>
                  import("@/views/admin/invoice/pages/dashboard.vue"),
              },
            ],
          },
          {
            name: "admin-trivias",
            path: "/admin/trivias",
            component: () => import("@/views/admin/trivias/trivias.vue"),
            redirect: {
              name: "admin-trivias-dashboard",
            },
            children: [
              {
                name: "admin-trivias-dashboard",
                path: "/admin/trivias/dashboard",
                component: () =>
                  import("@/views/admin/trivias/pages/dashboard.vue"),
              },
              {
                name: "admin-trivias-add",
                path: "/admin/trivias/add",
                component: () => import("@/views/admin/trivias/pages/add.vue"),
              },
              {
                name: "admin-trivias-edit",
                path: "/admin/trivias/edit",
                component: () => import("@/views/admin/trivias/pages/edit.vue"),
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "404",
    path: "/404",
    component: () => import("@/views/404/404.vue"),
    meta: {
      permissions: ["super", "admin", "store", "user"],
    },
  },
  {
    path: "*",
    redirect: {
      name: "auth",
    },
  },
  {
    path: "/",
    redirect: {
      name: "auth",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (Vue.$cookies.isKey("session")) {
    await store.dispatch("validToken");

    if (to?.matched?.some(({ meta }) => meta?.auth)) {
      return next({ name: "app", replace: true });
    } else if (
      to?.matched?.some(({ meta }) =>
        meta?.permissions?.some(
          (tags: { tags: string }) => tags === store.state?.role
        )
      )
    ) {
      return next();
    } else {
      let home = "";

      to?.matched?.forEach(({ meta }) => {
        const find = meta?.home?.find(
          ({ key }: { key: string }) => key === store.state?.role
        );
        find && (home = find?.path);
      });

      return next({ name: home });
    }
  } else {
    return to?.matched?.some(({ meta }) => meta?.app)
      ? next({ name: "auth", replace: true })
      : next();
  }
});

export default router;
