import Vue from 'vue';

const components = {
	'scroll-bar':	() => import('vue-perfect-scrollbar'),
	'v-date-timer':	() => import('../components/date-timer.vue'),
	'v-date-input':	() => import('../components/date-input.vue'),
	'v-date-years':	() => import('../components/date-years.vue'),
};

Object.entries(components).forEach(([name, component])=>{
	Vue.component(name, component);
});