import Vue from 'vue';

const $config = {
	position:'bottom-left',
	timeout: 3000
};

Vue.mixin({
	methods: {
		$toast:(type:string,msg:string)=>{
			switch(type){
				case 'success':
				case 'error':
				case 'warning':
				case 'info':
					return Vue.prototype.$dialog.notify[type]((msg || ''), $config);
				default:
					console.log('Tipo de alerta desconicida');
			}
		}
	},
});