import Vue from 'vue';
import vuetify from '@/plugins/vuetify';

const mixin = {
	shortName: (name:string) => {
		return (name || '').split(" ")?.map((n,i,a)=> i === 0 || i+1 === a?.length ? n[0] : null).join("");
	},
	cloneArs: (data:[])=>{
		return Object.assign([], data);
	},
	cloneObj: (data:object)=>{
		return JSON.parse(JSON.stringify(data));
	},
	isBreak: (w:number = <number> 960):boolean => {
		return vuetify.framework.breakpoint.width >= w;
	},
	coinFormat: (val:number = <number> 0):string => {
		const str = (val || 0).toLocaleString('en-US', { style:'currency', currency:'USD' })
		return str.replace(/[$]/,'$ ');
	},
	coinFormatVE: (val:number = <number> 0):string => {
		return (val || 0).toLocaleString('es-VE', {  style:'currency', currency:'VES' });
	},
	invoiceFormat: (val:number = <number> 0):string => {
		const str = (val || 0).toLocaleString('es-VE', {  style:'currency', currency:'VES' })
		return str.replace(/^Bs\.S\s+/, '');
	},
	toNumber: (val:string | number, decimal:string = <string> '.')=>{
		const str = String(val || '').replace( new RegExp(`[^0-9${decimal}]`,"g") ,'');
		return	Number( String(str || '').replace(/[,]/,'.') );
	},
	toInt: (val:string | number)=>{
		return	parseInt( String(val || '').replace(/[^0-9]/g,'') );
	},
	sortKey: (val:string):string =>{
		const ini = String(val||'').match(/^.{1,4}/);
		const end = String(val||'').match(/.{0,4}$/);
		return `${ini}...${end}`;
	},
	iconWeb: (img:string):string => {
		const name	= img?.toLocaleLowerCase();
		const brow	= ['chrome','edge','firefox','internet','opera','safari','tor','postmanruntime'];
		return require(`@/assets/img/browser/${brow.includes(name) ? name : 'default'}.svg`);
	},

	findItem: (arg:string):object => {
		switch (arg) {
      case "created":
        return {
          color: "#FFB400",
          text: { one: "Creado", multi: "Creadas", masc: "Creados" },
          icon: "mdi-plus-circle-outline",
        };
      case "approved":
        return {
          color: "#56CA00",
          text: { one: "Aprobado", multi: "Aprobadas", masc: "Aprobados" },
          icon: "mdi-check-circle-outline",
        };
      case "enabled":
        return {
          color: "#56CA00",
          text: { one: "Habilitada", multi: "Habilitadas", masc: "Habilitados" },
          icon: "mdi-check-circle-outline",
        };
      case "rejected":
        return {
          color: "#FF4C51",
          text: { one: "Rechazado", multi: "Rechazadas", masc: "Rechazados" },
          icon: "mdi-close-circle-outline",
        };
      case "finish":
        return {
          color: "#1E8DB5",
          text: {
            one: "Finalizado",
            multi: "Finalizadas",
            masc: "Finalizados",
          },
          icon: "mdi-stop-circle-outline",
        };
      case "verified":
        return {
          color: "#56CA00",
          text: {
            one: "Verificado",
            multi: "Verificadas",
            masc: "Verificados",
          },
          icon: "mdi-shield-check",
        };
      case "unverified":
        return {
          color: "#FF4C51",
          text: {
            one: "Sin Verificar",
            multi: "Sin Verificar",
            masc: "Sin Verificar",
          },
          icon: "mdi-shield-off",
        };
      case "pending":
        return {
          color: "#FFB400",
          text: { one: "Pendiente", multi: "Pendientes", masc: "Pendientes" },
          icon: "mdi-plus-circle-outline",
        };
      case "total":
        return {
          color: "#1E8DB5",
          text: { one: "Total", multi: "Total", masc: "Total" },
          icon: "mdi-star-circle-outline",
        };
      case "buyed":
        return {
          color: "#56CA00",
          text: { one: "Vendido", multi: "Vendidas", masc: "Vendidos" },
          icon: "mdi-check-circle-outline",
        };
      case "resta":
        return {
          color: "#FFB400",
          text: { one: "Restante", multi: "Restantes", masc: "Restantes" },
          icon: "mdi-minus-circle-outline",
        };
      case "banner-enabled":
        return {
          color: "#56CA00",
          text: {
            one: "Habilitado",
            multi: "Habilitadas",
            masc: "Habilitados",
          },
          icon: "mdi-image-plus-outline",
        };
      case "banner-disabled":
        return {
          color: "#FF4C51",
          text: {
            one: "Deshabilitado",
            multi: "Desabilitadas",
            masc: "Deshabilitados",
          },
          icon: "mdi-image-off-outline",
        };
      case "puzzle-enabled":
        return {
          color: "#56CA00",
          text: {
            one: "Habilitado",
            multi: "Habilitadas",
            masc: "Habilitados",
          },
          icon: "mdi-puzzle-plus-outline",
        };
      case "puzzle-disabled":
        return {
          color: "#FF4C51",
          text: {
            one: "Deshabilitado",
            multi: "Desabilitadas",
            masc: "Deshabilitados",
          },
          icon: "mdi-puzzle-remove-outline",
        };
      case "users-enabled":
        return {
          color: "#56CA00",
          text: {
            one: "Habilitado",
            multi: "Habilitadas",
            masc: "Habilitados",
          },
          icon: "mdi-account-check-outline",
        };
      case "users-disabled":
        return {
          color: "#FF4C51",
          text: {
            one: "Deshabilitado",
            multi: "Desabilitadas",
            masc: "Deshabilitados",
          },
          icon: "mdi-account-remove-outline",
        };
      default:
        return {
          color: "#000000",
          text: {
            one: "Desconocido",
            multi: "Desconocido",
            masc: "Desconocidos",
          },
          icon: "mdi-circle-off-outline",
        };
    }
	},
	findPayment: (arg:string):object => {
		switch(arg){
			case 'vippo':
				return { color:'#FFB400', text:'Vippo',			icon:'mdi-cart-arrow-up' };
			case 'movil':
				return { color:'#56CA00', text:'Pago Movil',	icon:'mdi-cart-check' };
			case 'bank':
				return { color:'#FF4C51', text:'Transferencia',	icon:'mdi-cart-remove' };
			case 'free':
				return { color:'#FF4C51', text:'Gratis',		icon:'mdi-cart-remove' };
			default:
				return { color:"#000000", text:'-',	icon:'mdi-circle-off-outline' };
		}
	},
	findType: (arg:string):object => {
		switch(arg){
			case 'super':
				return { icon:'mdi-crown-outline',	name:'Super Admin'};
			case 'admin':
				return { icon:'mdi-key-star',		name:'Administrador'};
			case 'store':
				return { icon:'mdi-store',			name:'Tienda'};
			case 'user':
				return { icon:'mdi-account-reactivate', name:'Promotor' };
			default:
				return { icon:'mdi-account-reactivate', name:'N/S' }
		}
	},
	filterBool:(sw:boolean = <boolean> false):string=>{
		return sw ? 'enabled' : 'disabled';
	},
	viewPass: (show:boolean):object=>{
		return show ? { icon:'mdi-eye-off-outline', type:'text' } : { icon:'mdi-eye-outline', type:'password' } ;
	},
	ramdonPass:( length:number, numb:number, symb:number, uper:number, lows:number ):string=>{
		let pass = '';
		const join = length - numb - symb - uper - lows;
		
		const loop = [
			{num:numb, por:10, mas:48},
			{num:symb, por:15, mas:33},
			{num:uper, por:26, mas:65},
			{num:lows, por:26, mas:97},
			{num:join, por:94, mas:33},
		];

		loop.forEach(({num, por, mas})=>{
			for(let i=0; i < num; i++) {
				pass += String.fromCharCode(Math.floor(Math.random() * por) + mas);
			}
		});

		return pass.split('').sort(()=>( 0.5-Math.random() )).join('');
	},
	filterTable:(_:string, search:string, item:{items?:object} )=>{
		const tmp = { ...item, ...item?.items };
		delete tmp.items;
		return Object.values(tmp).some(v=>v&&v.toString().toLowerCase().includes(search));
	}
};

Vue.mixin({methods:mixin});

const rules = {
	reqs:(v:string) => !!v									|| 'Campo requerido',
	rept:(v:string, r:string)=> (v === r)					|| 'Las contraseñas no coinciden',
	mins:(v:string, n:number)=> ((v||'').length >= n)		|| `Debe tener al menos ${n} caracteres`,
	maxs:(v:string, n:number)=> ((v||'').length <= n)		|| `Máximo ${n} caracteres`,
	nums:(v:string) => /^[0-9]*$/.test(v)					|| 'No es un número válido',
	nume:(v:string) => /^[0-9.]*$/.test(v)					|| 'No es un número válido',
	lows:(v:string) => /[a-z]/.test(v)						|| 'Requiere al menos una minúscula',
	uper:(v:string) => /[A-Z]/.test(v)						|| 'Requiere al menos una mayúscula',
	symb:(v:string) => /[<>&%*\-@.#?"]/g.test(v)			|| 'Requiere al menos un carácter especial',
	numb:(v:string) => /[0-9]/g.test(v)						|| 'Requiere al menos un numéro',
	mail:(v:string) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v)	|| 'Email inválido',
	mone:(v:string) => /^\d+(\.\d{0,4})?$/.test(v)			|| 'No es un formato válido',
};

const typeUser = [
	{ key:'user',	name:'Promotor'		},
	{ key:'store',	name:'Tienda'		},
	{ key:'admin',	name:'Administrador'},
];

const productMobile = {
	name:		{val:'', type:'string',	rul:true,	label:'Nombre del producto'},
	category:	{val:'', type:'select',	rul:true,	label:'Categoría o departamento'},
	stock:		{val:'', type:'number',	rul:true,	label:'Cantidad disponible',	col:6},
	price:		{val:'', type:'money',	rul:true,	label:'Precio',					col:6},
	details:	{val:'', type:'texto',	rul:true,	label:'Descripción detallada del producto'},
	images:		{val:'', type:'images',	rul:true,	label:'Imágenes del producto'},
	brand:		{val:'', type:'string',	rul:true,	label:'Marca o fabricante',		col:6},
	color:		{val:'', type:'string',	rul:null,	label:'Color',					col:3},
	size:		{val:'', type:'string',	rul:null,	label:'Tamaño',					col:3},
	options:	{val:'', type:'texto',	rul:null,	label:'Características adicionales o especificaciones técnicas'},
	productId:	{val:'', type:'string',	rul:null,	label:'Identificación del producto (SKU o UPC)'},
	shipping:	{val:'', type:'texto',	rul:null,	label:'Envío y políticas de entrega'},
};

const masks = {
	money:{
		decimal: '.',
		thousands: ',',
		prefix: '$ ',
		precision: 2,
		masked: true,
		default: ''
	},
	entero: (v:string | number, min:number = <number> 0, max:number | null = null ) => {
		const num = String(v || '').replace(/[^0-9]/g,'')
		const nan = Number(num);
		return String( ( ( min != null ) &&  (nan <= min) ? min : ( (max != null) && (nan > max)  ? max : nan ) ) );
	}
}

export { rules, masks, productMobile, typeUser };