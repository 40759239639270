import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

import en from './locale/en';
import es from './locale/es';

export default new Vuetify({
	treeShake: true,
	customProperties: true,
	lang: {
		locales: { en, es },
		current: localStorage?.currentLang || 'en',
	},
	iconfont: 'mdi',
	theme: {
		options:{
			customProperties: true
		},
		dark: false,
		themes:{
			light:{
				primary:	'#6200EE',
				secondary:	'#6153D3',
				tercery:	'#4300b1',
				cuartery:	'#1D0351',
				grey:		'#989898',
				info:		'#16B1FF',
				success:	'#56CA00',
				warning:	'#FFB400',
				error:		'#FF4C51',
			},
		}
	}
});