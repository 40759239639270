import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import VueCookies from 'vue-cookies';
import vueMoment from "vue-moment";
import moment from "moment";
import esLang from 'moment/locale/es';
import VueMeta from 'vue-meta';
import VueMask from 'v-mask';
import money from 'v-money';
import Croppa from 'vue-croppa';
import VuetifyDialog from 'vuetify-dialog';

import '@/plugins/toast';
import './services/components';
import './services/functions';
import 'vue-croppa/dist/vue-croppa.css';
import 'vuetify-dialog/dist/vuetify-dialog.css';

Vue.use(VueMask);
Vue.use(VueCookies);
Vue.use(Croppa);
Vue.use(VueMeta,{refreshOnceOnNavigation:true});
Vue.use(vueMoment,{moment});
Vue.use(VuetifyDialog,{context:{vuetify}});
Vue.use(money, {precision: 4});

moment.updateLocale('es',esLang);

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app');