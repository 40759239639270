<template>
	<v-app id="app">
		<v-main>
			<transition name="slide-fade" mode="out-in" appear>
				<router-view/>
			</transition>
		</v-main>
	</v-app>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
	name: 'App',
	metaInfo: {
		title: 'AD Race',
		titleTemplate: '%s - Administrador',
		htmlAttrs: {
			lang: 'es',
			amp: false,
		},
		meta: [
			{ hid: 'og:site-name',	property: 'og:site_name',	content: 'ad-race' },
			{ hid: 'og-url',		property: 'og:url',			content: document.location.href },
			{ hid: 'og-type',		property: 'og:type',		content: 'website' },
			{ hid: 'og-title',		property: 'og:title',		content: 'AD Race' },
			{ hid: 'og-description',property: 'og:description',	content: 'Administrador de APP' },
			{ hid: 'og-image',		property: 'og:image',		content: require('@/assets/img/meta/meta.jpg') },
			{ hid: 'twitter-card',	name: 'twitter:card',		content: 'summary_large_image' }
		],
	}
});
</script>

<style lang="scss">
	@import '@/assets/scss/app';
</style>